import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faCheck,
  faUpload,
  faCogs,
  faMicrophone,
  faFileAlt,
  faTasks,
  faFolderOpen,
  faCloudDownloadAlt,
  faFileCode,
  faFilePdf,
  faLightbulb,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AudioRecorder from "vue2-audio-recorder";
Vue.use(AudioRecorder);

library.add(
  faSpinner,
  faCheck,
  faUpload,
  faCogs,
  faMicrophone,
  faFileAlt,
  faTasks,
  faFolderOpen,
  faCloudDownloadAlt,
  faFileCode,
  faFilePdf,
  faLightbulb,
  faInfoCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
