<template>
  <div id="app">
    <img
      alt="Vue logo"
      src="./assets/logo.png"
      class="mx-auto w-30percent lg:max-w-logo"
    />
    <AudioUploader msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import AudioUploader from "./components/AudioUploader.vue";

export default {
  name: "App",
  components: {
    AudioUploader,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

.w-30percent {
  width: 30%;
}

.lg\:max-w-logo {
  max-width: 100px;
}
</style>
