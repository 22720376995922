<template>
  <div class="container max-w-3xl px-4 py-6 mx-auto">
    <div class="p-6 bg-white rounded shadow-md">
      <section class="mb-8">
        <div class="flex items-center justify-between">
          <h1 class="mb-4 text-2xl font-semibold">Record</h1>
          <div class="relative">
            <font-awesome-icon
              icon="info-circle"
              size="2x"
              class="cursor-pointer"
              color="blue"
              @mouseover="showTooltip = true"
              @mouseout="showTooltip = false"
            />
            <div
              class="absolute z-10 w-64 p-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded shadow-lg tooltip-container"
              v-if="showTooltip"
            >
              Click the microphone button to start recording. The button will
              change to a stop button while recording is in progress. Click the
              stop button to stop recording, and the file will automatically be
              processed.
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <audio-recorder
            ref="recorder"
            mode="minimal"
            :after-recording="callback"
          ></audio-recorder>
        </div>
      </section>

      <!-- <section class="mb-8">
        <h1 class="mb-4 text-2xl font-semibold">Record Audio</h1>
        <div class="flex items-center">
          <audio-recorder
            ref="recorder"
            mode="minimal"
            :after-recording="callback"
          ></audio-recorder>
          <button
            v-if="!isRecording"
            @click="startRecording"
            class="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Record
          </button>
          <button
            v-else
            @click="stopRecording"
            class="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            Stop
          </button>
          <span class="ml-4 font-semibold">{{
            isRecording ? "Recording..." : ""
          }}</span>
          <span class="ml-auto text-gray-700" v-if="isRecording"
            >{{ recordedTime }} s</span
          >
        </div>
      </section> -->
      <hr class="mb-8 border-b border-gray-200" />
      <section>
        <div class="flex items-center justify-between">
          <h1 class="mb-4 text-2xl font-semibold">Upload Audio File</h1>
          <div class="relative">
            <font-awesome-icon
              icon="info-circle"
              size="2x"
              color="blue"
              class="cursor-pointer"
              @mouseover="showTooltip2 = true"
              @mouseout="showTooltip2 = false"
            />
            <div
              class="absolute z-10 w-64 p-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded shadow-lg tooltip-container"
              v-if="showTooltip2"
            >
              Click the "Choose File" button to select an audio file. After
              selecting the file, click the "Upload" button to upload the file.
            </div>
          </div>
        </div>
        <div class="mb-4">
          <input
            type="file"
            @change="onFileChange"
            :disabled="loading"
            class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <button
          @click="uploadFile"
          :disabled="loading"
          class="flex items-center justify-center w-full px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          <span v-if="!loading">Upload</span>
          <div v-else class="spinner"></div>
        </button>
        <transition name="fade" mode="out-in" v-if="loading">
          <div :key="status" class="mt-4">
            <div class="flex justify-center mb-2">
              <font-awesome-icon
                :icon="statusIcon"
                size="3x"
                spin
                class="text-blue-600"
                v-if="statusIconSpin"
              />
              <font-awesome-icon
                :icon="statusIcon"
                size="3x"
                class="text-blue-600"
                v-else
              />
            </div>
            <p class="mt-2 font-semibold text-center text-blue-600">
              {{ status }}
            </p>
          </div>
        </transition>

        <div class="flex mt-4">
          <a
            :href="mdDownloadUrl"
            :download="mdFileName"
            v-if="mdDownloadUrl"
            class="flex items-center px-4 py-2 font-semibold text-blue-600 bg-white border border-blue-600 rounded-md hover:bg-blue-100 hover:text-blue-700"
          >
            <font-awesome-icon :icon="['fas', 'file-code']" class="mr-2" />
            Download Markdown file
          </a>
          <a
            :href="downloadUrl"
            :download="fileName"
            v-if="downloadUrl"
            class="flex items-center px-4 py-2 ml-4 font-semibold text-blue-600 bg-white border border-blue-600 rounded-md hover:bg-blue-100 hover:text-blue-700"
          >
            <font-awesome-icon :icon="['fas', 'file-pdf']" class="mr-2" />
            Download PDF file
          </a>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
const UPLOAD_AUDIO_URL = "https://sttapiv3.phox.app/api/upload-audio";
const CONVERT_TO_PDF_URL = "https://sttapiv3.phox.app/api/convert-to-pdf";
// const UPLOAD_AUDIO_URL = "https://sttapi.phox.app/api/upload-audio";
// const CONVERT_TO_PDF_URL = "https://sttapi.phox.app/api/convert-to-pdf";
// const UPLOAD_AUDIO_URL = "http://localhost:3000/api/upload-audio";
// const CONVERT_TO_PDF_URL = "http://localhost:3000/api/convert-to-pdf";
const TIMER_DELAY = 1000;

import RecordRTC from "recordrtc";

export default {
  name: "AudioUploader",
  data() {
    return this.initialData();
  },
  methods: {
    async callback(data) {
      console.debug(data);
      const recordedBlob = data.blob;
      const file = new File([recordedBlob], "recorded_audio.wav");
      await this.processAudio(file);
    },
    initialData() {
      return {
        mdDownloadUrl: "",
        mdFileName: "",
        file: null,
        loading: false,
        downloadUrl: "",
        fileName: "",
        recorder: null,
        isRecording: false,
        status: "Ready to record",
        statusIcon: "microphone",
        statusIconSpin: false,
        recordedTime: 0,
        timerInterval: null,
        showTooltip: false,
        showTooltip2: false,
      };
    },
    resetState() {
      Object.assign(this.$data, this.initialData());
    },
    setStatusAndIcon(status, icon, spin = false) {
      this.status = status || "Ready to record";
      this.statusIcon = icon || "microphone";
      this.statusIconSpin = spin;
    },
    async startRecording() {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        this.recorder = new RecordRTC(audioStream, {
          type: "audio",
          mimeType: "audio/wav",
          recorderType: RecordRTC.StereoAudioRecorder,
        });

        this.recorder.startRecording();

        this.recordedTime = 0;
        this.timerInterval = setInterval(() => {
          this.recordedTime++;
        }, TIMER_DELAY);

        this.isRecording = true;
      } catch (error) {
        this.setStatusAndIcon(`Error: ${error.message}`, "microphone", false);
        console.error("startRecording error:", error);
      }
    },
    async processAudio(file) {
      this.resetState();
      if (!file && !this.recorder) return;

      this.loading = true;
      this.setStatusAndIcon("Processing audio...", "cogs", true);

      try {
        if (!file) {
          const recordedBlob = await new Promise((resolve) => {
            this.recorder.stopRecording(() => {
              resolve(this.recorder.getBlob());
            });
          });

          file = new File([recordedBlob], "recorded_audio.wav");
        }

        await this.uploadAndProcessAudio(file);
      } catch (error) {
        console.error(error);
        this.handleError("Error processing the audio");
      } finally {
        this.setStatusAndIcon("Ready to record", "microphone", false);
        this.loading = false;
      }
    },

    async uploadAndProcessAudio(file) {
      const formData = new FormData();
      formData.append("audio", file);

      const stages = [
        { delay: 0, status: "Uploading file...", icon: "upload", spin: true },
        {
          delay: 10000,
          status: "Transcribing...",
          icon: "microphone",
          spin: true,
        },
        {
          delay: 20000,
          status: "Summarizing...",
          icon: "file-alt",
          spin: true,
        },
        {
          delay: 35000,
          status: "Drawing conclusions...",
          icon: "lightbulb",
          spin: true,
        },
        {
          delay: 50000,
          status: "Identifying tasks...",
          icon: "tasks",
          spin: true,
        },
        {
          delay: 65000,
          status: "Creating files...",
          icon: "folder-open",
          spin: true,
        },
        {
          delay: 80000,
          status: "Preparing for download...",
          icon: "cloud-download-alt",
          spin: true,
        },
        { delay: 90000, status: "Done.", icon: "check", spin: false },
      ];

      stages.forEach((stage) => {
        setTimeout(
          () => this.setStatusAndIcon(stage.status, stage.icon, stage.spin),
          stage.delay
        );
      });

      try {
        const response = await fetch(UPLOAD_AUDIO_URL, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          await this.handleUploadResponse(response, file);
        } else {
          this.handleError("Error uploading the file");
        }
      } catch (error) {
        console.error(error);
        this.handleError("Error uploading the file");
      } finally {
        this.setStatusAndIcon("Ready to record", "microphone", false);
        this.loading = false;
      }
    },
    async handleUploadResponse(response, file) {
      const mdBlob = await response.blob();
      const arrayBuffer = await mdBlob.arrayBuffer();
      const mdContent = new TextDecoder().decode(arrayBuffer);

      this.mdDownloadUrl = URL.createObjectURL(mdBlob);
      this.mdFileName = `${file.name}.md`;

      const pdfBlob = await fetch(CONVERT_TO_PDF_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ markdown: mdContent }),
      }).then((res) => res.blob());

      this.downloadUrl = URL.createObjectURL(pdfBlob);
      this.fileName = `${file.name}.pdf`;
    },
    handleError(message) {
      this.setStatusAndIcon(message, "exclamation-triangle", false);
    },
    async stopRecording() {
      if (!this.recorder) return;

      this.isRecording = false;
      clearInterval(this.timerInterval);

      try {
        const recordedBlob = await new Promise((resolve) => {
          this.recorder.stopRecording(() => {
            resolve(this.recorder.getBlob());
          });
        });

        const file = new File([recordedBlob], "recorded_audio.wav");

        this.processAudio(file);
      } catch (error) {
        console.error(error);
        this.handleError("Error processing the audio");
      }
    },

    onFileChange(e) {
      this.file = e.target.files[0];
    },
    uploadFile() {
      this.loading = true;
      this.processAudio(this.file);
    },
  },
};
</script>

<style scoped>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* Add the spinner styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.tooltip-container {
  right: 100%;
  transform: translateY(-50%);
}
</style>
